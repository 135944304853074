import './toolCode.css';
import { CopyOutlined } from '@ant-design/icons';
import { Clipboard } from '@nextgis/utils';
import { Button, Tooltip } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ToolCode: React.FC<{ code: string }> = ({ code }) => {
  const { t } = useTranslation();
  const codeRef = useRef<HTMLDivElement>(null);
  const [copyText, setCopyText] = useState(t('operation.copyTT'));

  return (
    <div className="code-snippet ">
      <div style={{ whiteSpace: 'pre-wrap', position: 'relative' }}>
        <Tooltip title={copyText}>
          <Button
            className="copy-code-button"
            onBlur={() => {
              setCopyText(t('operation.copyTT'));
            }}
            onClick={() => {
              Clipboard.copy(codeRef.current?.innerText || '');
              setTimeout(() => {
                setCopyText(t('operation.copiedTT'));
              }, 100);
            }}
            type="primary"
            icon={<CopyOutlined />}
          />
        </Tooltip>
        <div ref={codeRef}>{code}</div>
      </div>
    </div>
  );
};

export { ToolCode };
