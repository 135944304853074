export const initChatra = (locale) => {
  if (
    !window.toolboxConfig.chatra_id ||
    !window.toolboxConfig.chatra_group_id
  ) {
    console.log('Could not initialize chatra, identifiers are not defiened');
    return;
  }

  window.ChatraID = window.toolboxConfig.chatra_id;
  window.ChatraGroupID = window.toolboxConfig.chatra_group_id;
  window.ChatraSetup = {
    language: locale,
  };

  const c = 'Chatra';
  const s = document.createElement('script');
  window[c] =
    window[c] ||
    function () {
      (window[c].q = window[c].q || []).push(arguments);
    };
  s.async = true;
  s.src =
    (document.location.protocol === 'https:' ? 'https:' : 'http:') +
    '//call.chatra.io/chatra.js';
  if (document.head) document.head.appendChild(s);
};

/** @function
 * @param {string} username
 * @param {string} email
 */
export const updateChatraUser = (username, email) => {
  window.Chatra('updateIntegrationData', {
    name: username,
    email: email,
  });
};

/** @function
 * @param {string | undefined} username
 * @param {string | undefined} email
 * @param {string} autoMessage
 * @param {Object} order
 *
 */
export const openPrefilledOnTaskChatra = (username, email, autoMessage) => {
  window.Chatra('updateIntegrationData', {
    name: username,
    email,
  });

  window.Chatra('openChat');

  setTimeout(() => {
    window.Chatra('sendAutoMessage', autoMessage);
  }, 50);
};

export const openChatra = () => {
  window.Chatra('openChat');
};
