import React, { useEffect, useRef } from 'react';

import './ToolDocs.css';

const ToolDocs: React.FC<{ docs: string }> = ({ docs }) => {
  const docsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = docsRef.current;
    if (el) el.innerHTML = docs;
  });

  return (
    <div
      style={{
        padding: '0px 6px',
        color: '#231f20',
      }}
    >
      <div className="docs-container" ref={docsRef}></div>
    </div>
  );
};

export { ToolDocs };
